
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
interface listData {
  loading: boolean;
  empty: string;
  dataSource: any[];
  icon: string;
  action?: string;
}
@Component({
  name: "MessagesList",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class MessagesList extends Vue {
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop()
  readonly listType!: listData;

  HandleDetails(value: any): void {
    this.$emit("sendDetails", value);
  }
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
