var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-all"},[_c('h2',{staticClass:"message-all-title font18 black-font"},[_vm._v("全部消息")]),_c('div',{staticClass:"message-all-header"},[_c('a-button',{staticClass:"message-all-back",attrs:{"type":"link","icon":"left"},on:{"click":_vm.goBack}},[_vm._v(" 返回 ")])],1),_c('div',{staticClass:"message-all-content"},[_c('a-menu',{staticClass:"message-menu",attrs:{"mode":"horizontal"},on:{"click":_vm.menuChange},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},[_c('a-menu-item',{key:"all",staticClass:"message-menu-item font16 gray-font"},[_c('a-badge',{attrs:{"count":"0","offset":[12, 6],"numberStyle":{
            minWidth: '18px',
            height: '18px',
            linHeight: '18px',
          }}},[_c('span',[_vm._v("全部")])])],1),_c('a-menu-item',{key:"share",staticClass:"message-menu-item font16 gray-font"},[_c('a-badge',{attrs:{"count":"0","offset":[12, 6],"numberStyle":{
            minWidth: '18px',
            height: '18px',
            linHeight: '18px',
          }}},[_c('span',[_vm._v("分享")])])],1),_c('a-menu-item',{key:"team",staticClass:"message-menu-item font16 gray-font"},[_c('a-badge',{attrs:{"count":"0","offset":[12, 6],"numberStyle":{
            minWidth: '18px',
            height: '18px',
            linHeight: '18px',
          }}},[_c('span',[_vm._v("企业团队")])])],1),_c('a-menu-item',{key:"coop",staticClass:"message-menu-item font16 gray-font"},[_c('a-badge',{attrs:{"count":"0","offset":[12, 6],"numberStyle":{
            minWidth: '18px',
            height: '18px',
            linHeight: '18px',
          }}},[_c('span',[_vm._v("协作")])])],1)],1),_c('div',{staticClass:"list"},[_c('MessageList',{attrs:{"listType":{
          loading: _vm.loading,
          empty: '暂无消息',
          dataSource: _vm.listData,
          icon: 'inform',
        }},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('span')]},proxy:true}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }